import React, { useEffect, useState } from "react";
import PageBanner from "./PageBanner";
import Input from "./Input";
import CourseAccordian from "./CourseAccordian";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../constants/routes";
import { Endpoints } from "../constants/Endpoints";
import { processData } from "../utils";
import CourseAccordianItem from "./CourseAccordianItem";
import { useDispatch, useSelector } from "react-redux";
import { updateCartCount } from "../redux/ActionCreator";
import { Helmet } from "react-helmet-async";
import PackageCard from "./PackageCard";

const CourseBooking = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [searchInput, setSearchInput] = useState(
    searchParams.get("search") || ""
  );
  const [relatives, setRelatives] = useState(-1);
  const [flatData, setFlatData] = useState([]);
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const count = useSelector(({ app }) => app.cartCount);
  const dispatch = useDispatch();
  const [isCourse, setIscourse] = useState(false);
  const cartCount = useSelector(({ app }) => app.cartCount);
  const [packageData, setPackageData] = useState();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.BASE_URL}/courses/listing`);
        let formatted = processData(res.data.data);
        setData(formatted);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  function flattenData(arr) {
    let result = [];
    for (let item of arr) {
      result = [
        ...result,
        ...item.data.map((t) => ({ ...t, title: t.title.toLowerCase() })),
      ];
    }
    return result;
  }

  useEffect(() => {
    if (!profileDetails.token) {
      setShowPopup(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [profileDetails, navigate]);

  useEffect(() => {
    (async () => {
      if (profileDetails.token) {
        try {
          const res = await axios.get(`${Endpoints.BASE_URL}/cart/get-cart`, {
            headers: {
              Authorization: `Bearer ${profileDetails.token}`,
            },
          });

          dispatch(updateCartCount(res.data.data.cart.length));
        } catch (e) {
          console.log(e);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (searchInput.length > 0) {
      let results = flattenData(data).filter((t) =>
        t.title.includes(searchInput.toLowerCase())
      );
      setFlatData(results);
    }
  }, [searchInput, searchParams.get("search"), data]);

  const rearrangeData = (data) => {
    const preSeaCourses = [];
    const otherCourses = [];

    data.forEach((item) => {
      if (!item || !item.data || item.data.length === 0) {
        return;
      }

      const visibleData = item.data.filter(
        (entry) => entry.booking_hidden !== 1
      );

      if (visibleData.length === 0) {
        return;
      }

      if (visibleData[0].category.primary_category === "Pre Sea Course") {
        preSeaCourses.push({ ...item, data: visibleData });
      } else {
        otherCourses.push({ ...item, data: visibleData });
      }
    });

    return [...preSeaCourses, ...otherCourses];
  };

  const rearrangedData = rearrangeData(data);
  const fetchPackages = async () => {
    try {
      const res = await axios.get(`${Endpoints.BASE_URL}/package/list`);
      setPackageData(res.data.resultMessage.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <>
      <Helmet>
        <title>Course Booking</title>
        <link
          rel="canonical"
          href="https://eurotechmaritime.org/course-booking"
        />
      </Helmet>
      <div>
        <PageBanner imgUrl="/gim.jpg" title="Course Booking" />
        <section className="container mx-auto my-10 md:px-4 px-3">
          <div className="grid grid-cols-12">
            {/* <Input
              className="col-span-12 md:col-span-6"
              placeholder="Search Course ..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            /> */}
            <div className="col-span-9" /> {/* Adjusted empty space */}
            <div className="col-span-12 md:col-span-3 mt-3 md:mt-0 flex justify-end">
              {cartCount > 0 && (
                <button
                  className="bg-[#1550A2] text-white p-5 py-3 rounded-xl"
                  onClick={() =>
                    navigate(routes.COURSE_BOOKING + routes.COURSE_DETAILS)
                  }
                >
                  View Selected Courses
                </button>
              )}
            </div>
            <div className="bg-[#32586D] col-span-12 text-white flex items-center py-6 px-5 rounded-3xl my-8">
              {/* Image on the left */}
              <img
                src="/discount.svg"
                alt="Discount Offer"
                className="w-16 h-16 md:w-24 md:h-24 mr-10 object-contain"
              />

              {/* Text on the right */}
              <h4 className="text-sm md:text-xl font-semibold">
                <span className="text-lg md:text-2xl font-bold">
                  Looking for the best deals?
                </span>
                <br /> Call 7025045000 for exclusive discounts on group bookings
                (5+ seats) across all courses! 
              </h4>
            </div>
          </div>
          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm animate-fadeIn">
              <div className="bg-white p-6 rounded-2xl shadow-2xl text-center max-w-sm w-full transform scale-95 animate-zoomIn">
                <p className="text-xl font-semibold text-gray-800">
                  🔒 Login Required
                </p>
                <p className="text-gray-600 mt-2">
                  You will be redirected in 3 seconds...
                </p>

                {/* Loading Dots Animation */}
                <div className="flex justify-center mt-3 space-x-1">
                  <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
                  <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce delay-150"></div>
                  <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce delay-300"></div>
                </div>
              </div>
            </div>
          )}

          {searchInput.length === 0 && (
            <div className="flex justify-center items-center bg-gray-200 rounded-lg p-2 w-full md:w-fit my-10 h-fit">
              <button
                className={`px-2 md:px-10 py-3 text-base md:text-xl rounded-md ${
                  isCourse
                    ? "bg-blue-600 text-white font-bold"
                    : "text-gray-600 font-medium"
                }`}
                onClick={() => setIscourse(true)}
              >
                Individual Courses
              </button>
              <div className="flex items-center">
                <button
                  className={`px-2 md:px-10 py-3 text-base md:text-xl rounded-md ml-3 ${
                    !isCourse
                      ? "bg-blue-600 text-white font-bold"
                      : "text-gray-600 font-medium"
                  }`}
                  onClick={() => setIscourse(false)}
                >
                  Package Courses
                </button>
                <img
                  src="/assets/special-offer.svg"
                  alt="special offer"
                  className="h-14 w-auto"
                />
              </div>
            </div>
          )}

          {profileDetails?.token && (
            <>
              {isCourse ? (
                <div className=" ">
                  {searchInput.length > 0 ? (
                    <>
                      {flatData?.length > 0 ? (
                        flatData?.map((item, index) => (
                          <>
                            <CourseAccordianItem
                              key={item.id}
                              index={index}
                              data={item}
                              relatives={relatives}
                              setRelatives={setRelatives}
                            />
                          </>
                        ))
                      ) : (
                        <div className="flex justify-center ">
                          <h1 className="md:text-[50px] text-[30px] font-bold my-20">
                            No Courses Found with matching name
                          </h1>
                        </div>
                      )}
                    </>
                  ) : (
                    rearrangedData?.map((item, index) => {
                      let showPreSeaHeader = false;
                      let showPostSeaHeader = false;

                      if (
                        item &&
                        item.data.length > 0 &&
                        item.data[0].category.primary_category ===
                          "Pre Sea Course" &&
                        index === 0
                      ) {
                        showPreSeaHeader = true;
                      }

                      if (
                        item &&
                        item.data.length > 0 &&
                        item.data[0].category.primary_category ===
                          "Post Sea Course" &&
                        index ===
                          rearrangedData.findIndex(
                            (i) =>
                              i.data.length > 0 &&
                              i.data[0].category.primary_category ===
                                "Post Sea Course"
                          )
                      ) {
                        showPostSeaHeader = true;
                      }

                      return (
                        <div key={item.id}>
                          {showPreSeaHeader && (
                            <div className="border-b-4 pb-5">
                              <h4 className="text-lg font-bold text-[#1A51A0]">
                                PRE SEA COURSES
                              </h4>
                            </div>
                          )}
                          {showPostSeaHeader && (
                            <div className="border-b-4 pb-5">
                              <h4 className="text-lg font-bold text-[#1A51A0]">
                                POST SEA COURSES
                              </h4>
                            </div>
                          )}
                          <CourseAccordian
                            title={item.name}
                            courses={item.data}
                            selectedCourse={selectedCourse}
                            setSelectedCourse={setSelectedCourse}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
              ) : (
                <>
                  <PackageCard
                    packageData={packageData}
                    selectedCourse={selectedCourse}
                    setSelectedCourse={setSelectedCourse}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                </>
              )}
            </>
          )}

          <div className="text-[#03014C] text-center my-14">
            <h1 className="text-[36px] font-bold mb-5">Instructions</h1>
            <ul className="text-[24px]">
              <li>1. Don’t use Back/Forward/Refresh button.</li>
              <li>
                2. Course Registration will be made only after successful
                payment.
              </li>
              <li>3. All banks Debit/Credit cards acceptable.</li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default CourseBooking;
