import axios from "axios";
import React, { useState } from "react";
import { Endpoints } from "../constants/Endpoints";
import { useNavigate } from "react-router-dom";
import { routes } from "../constants/routes";
import { IoIosArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { increaseCartCount } from "../redux/ActionCreator";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const PackageCard = ({ packageData }) => {
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [relatives, setRelatives] = useState({ package_id: -1, course_id: -1 });
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const dispatch = useDispatch();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }

  const fetchBatches = async (data) => {
    try {
      setBatches([]);
      const res = await axios.get(
        `${Endpoints.BASE_URL}/batches/get/list?course_id=${data.id}`
      );

      if (res?.data?.data && res?.data?.data.length > 0) {
        let status = false;
        for (let i of res?.data?.data) {
          if (i.status !== "Closed") {
            status = true;
          }
        }

        if (!status) {
          toast.success("All batches closed.");
        }
      }
      setBatches(res.data.data);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const toggleDropdown = (package_id, course_id) => {
    setDropdownVisible(!dropdownVisible);
    setRelatives({
      package_id: package_id,
      course_id: course_id,
    });
  };

  const parseCustomDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string") {
      console.error("Invalid date string:", dateStr);
      return null; // Return null if dateStr is not valid
    }

    try {
      const parts = dateStr.split(" ");
      if (parts.length !== 3) {
        console.error("Date string format is incorrect:", dateStr);
        return null;
      }

      const day = parseInt(parts[0], 10);
      const month = new Date(`${parts[1]} 1`).getMonth();
      const year = parseInt(parts[2], 10);

      // Validate parsed values
      if (isNaN(day) || isNaN(month) || isNaN(year)) {
        console.error("Invalid date parts:", { day, month, year });
        return null;
      }

      return new Date(year, month, day);
    } catch (error) {
      console.error("Error parsing date string:", dateStr, error);
      return null;
    }
  };

  const handleDateSelect = async (batch_id, courseData, batch) => {
    setDropdownVisible(false);

    setSelectedPackages((prevSelectedPackages) => {
      const updatedPackages = [...prevSelectedPackages];

      // Check if the package exists in the selected packages array
      const existingPackageIndex = updatedPackages.findIndex(
        (pkg) => pkg.package_id === relatives.package_id
      );

      if (existingPackageIndex !== -1) {
        let updatedData = [...updatedPackages[existingPackageIndex].data];

        // Check for any date range overlap with other courses in the same package
        const conflictingDateIndex = updatedData.findIndex((existingCourse) => {
          const existingBatchStartDate = parseCustomDate(
            existingCourse.selected_batch_date
          );
          const existingBatchEndDate = parseCustomDate(existingCourse.end_date);
          const selectedBatchStartDate = new Date(batch.start_date);
          const selectedBatchEndDate = new Date(batch.end_date);

          return (
            (selectedBatchStartDate >= existingBatchStartDate &&
              selectedBatchStartDate <= existingBatchEndDate) || // Start date falls within an existing batch's range
            (selectedBatchEndDate >= existingBatchStartDate &&
              selectedBatchEndDate <= existingBatchEndDate) || // End date falls within an existing batch's range
            (existingBatchStartDate >= selectedBatchStartDate &&
              existingBatchStartDate <= selectedBatchEndDate) // Existing range falls within the new batch range
          );
        });
        if (conflictingDateIndex !== -1) {
          toast.info(
            "You have already selected this date for another course. Please choose a different date."
          );
          return prevSelectedPackages;
        }

        // If no same date conflict, update or add the course
        const existingCourseIndex = updatedData.findIndex(
          (course) => course.course_id === courseData.id
        );

        if (existingCourseIndex !== -1) {
          updatedData[existingCourseIndex] = {
            course_id: courseData.id,
            requested_batch_id: batch_id,
            selected_batch_date: formatDate(batch.start_date),
            category_id: courseData.category_id,
          };
        } else {
          updatedData.push({
            course_id: courseData.id,
            requested_batch_id: batch_id,
            selected_batch_date: formatDate(batch.start_date),
            end_date: formatDate(batch.end_date),
            category_id: courseData.category_id,
          });
        }

        // Update the package data
        updatedPackages[existingPackageIndex] = {
          ...updatedPackages[existingPackageIndex],
          data: updatedData,
        };
      } else {
        // If the package doesn't exist, add the new package with the selected course data
        updatedPackages.push({
          package_id: relatives.package_id,
          data: [
            {
              course_id: courseData.id,
              requested_batch_id: batch_id,
              selected_batch_date: formatDate(batch.start_date),
              end_date: formatDate(batch.end_date),
              category_id: courseData.category_id,
            },
          ],
        });
      }

      return updatedPackages;
    });
  };

  // final add to cart
  const addToCart = async () => {
    if (selectedPackages.length === 0) {
      alert("Please select a package");
      return;
    }
    if (profileDetails.token) {
      try {
        const payload = {
          package_id: selectedPackages[0].package_id,
          courses: selectedPackages[0]?.data.reduce((acc, course) => {
            acc[course.course_id] = course.requested_batch_id;
            return acc;
          }, {}),
        };
        // Add course to cart API call
        const cartResponse = await axios.post(
          `${Endpoints.BASE_URL}/cart/add-cart`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${profileDetails.token}`,
            },
          }
        );
        if (cartResponse) {
          navigate(`${routes.COURSE_BOOKING}/details`);
        }
      } catch (e) {}
    } else {
      navigate(routes.LOGIN);
    }
  };

  return (
    <div className="p-4 max-w-6xl mx-auto">
      {packageData && packageData.length > 0 ? (
        packageData.map((item, i) => (
          <div
            className="drop-shadow rounded-lg border px-5 py-4 my-4 bg-white"
            key={i}
          >
            {/* Header with Package Name and Price */}
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <h2 className="text-lg sm:text-xl font-semibold text-[#205277]">
                {item?.packageName}
              </h2>
              <div className="text-lg sm:text-xl font-semibold mt-2 sm:mt-0">
                {item?.discount > 0 ? (
                  <div>
                    <div className="flex items-baseline">
                      <p className="text-red-600 text-lg line-through mr-2">
                        ₹{item?.price}
                      </p>
                      <p className="text-lg">
                        ₹{item?.price - (item?.price * item?.discount) / 100}
                      </p>
                    </div>
                    <p className="text-sm text-gray-600">Limited Time Offer</p>
                  </div>
                ) : (
                  <p>₹{item?.price}</p>
                )}
              </div>
            </div>

            {/* Image and Course Details */}
            <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center mt-4">
              {/* Image Container */}
              <div className="w-full sm:w-1/3 flex-shrink-0">
                <img
                  src={item?.packageImage}
                  alt=""
                  className="w-full h-[130px] sm:h-[150px] rounded-md object-cover"
                />
              </div>

              {/* Course Details */}
              <div className="w-full sm:w-2/3">
                {item?.courses &&
                  item?.courses.length > 0 &&
                  item?.courses.map((data, idx) => (
                    <div
                      key={idx}
                      className="my-2 flex flex-col sm:flex-row justify-between items-center gap-3"
                    >
                      <h3 className="text-md flex-[2]">
                        {idx + 1}. {data.title}
                      </h3>

                      {/* View Course Details Button */}
                      <button
                        className="text-sm sm:text-base font-bold text-[#205277] whitespace-nowrap flex-[1]"
                        onClick={() =>
                          navigate(
                            `${routes.COURSE_BOOKING}/${data?.slug
                              ?.replace(/\s+/g, "-")
                              .toLowerCase()}-${data.id}`,
                            { state: { id: data.id } }
                          )
                        }
                      >
                        View Course Details
                      </button>

                      {/* Select Batch Button with Dropdown */}
                      <div className="relative flex-[1]">
                        <button
                          className="bg-[#205277] text-white p-2 px-6 rounded-xl font-bold flex items-center justify-center sm:whitespace-nowrap"
                          onClick={() => {
                            toggleDropdown(item.id, data.id);
                            fetchBatches(data);
                          }}
                        >
                          {selectedPackages?.some(
                            (pkg) =>
                              pkg.package_id === item.id &&
                              pkg.data.some(
                                (course) => course.course_id === data.id
                              )
                          )
                            ? selectedPackages
                                ?.find((pkg) => pkg.package_id === item.id)
                                .data.find(
                                  (course) => course.course_id === data.id
                                ).selected_batch_date
                            : "Select Batch"}
                          <IoIosArrowDropdown className="ml-2" />
                        </button>

                        {/* Dropdown Menu */}
                        {dropdownVisible &&
                          relatives?.course_id === data.id &&
                          relatives?.package_id === item?.id && (
                            <div className="absolute w-full top-10 bg-white rounded-lg shadow-xl z-10 max-h-48 overflow-y-auto mt-2">
                              {batches &&
                                batches.length > 0 &&
                                batches.map(
                                  (batch) =>
                                    batch.status !== "Closed" &&
                                    dayjs().isBefore(batch?.start_date) &&
                                    batch?.available_seats !== 0 && (
                                      <div
                                        key={batch.id}
                                        className="py-2 px-4 hover:bg-gray-200 flex justify-between items-center cursor-pointer"
                                        onClick={() =>
                                          handleDateSelect(
                                            batch.id,
                                            data,
                                            batch
                                          )
                                        }
                                      >
                                        <span className="text-sm font-semibold text-gray-800">
                                          {formatDate(batch?.start_date)}
                                        </span>
                                        <span className="text-xs text-blue-600">
                                          ({batch?.available_seats} seats)
                                        </span>
                                      </div>
                                    )
                                )}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Book Now Button */}
            <div className="flex justify-end mt-4">
              <button
                className="bg-[#204159] text-white font-bold py-2 px-8 rounded-lg"
                onClick={addToCart}
              >
                Book Now
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center mt-8">
          <h1 className="text-2xl font-bold text-gray-800">
            No batches available
          </h1>
        </div>
      )}
    </div>
  );
};

export default PackageCard;
