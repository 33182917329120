import React, { useState } from "react";
import PopupForm from "../Components/coursePopup.jsx";

const MBACoursePage = ({
  title = "Master the Future of Global Supply Chains",
  description = "A globally recognized IMU-affiliated MBA program designed for careers in logistics, transportation, and supply chain management, with a 45-day mandatory internship for real-world experience",
  imageSrc = "/course_img.png",
}) => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Courses", link: "/courses-list" },
  ];

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <PopupForm
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        pageMsg="MBA Course Enquiry"
      />
      {/* Image Section */}
      <div className="relative w-full h-[50vh] overflow-hidden">
        <img
          src={imageSrc}
          alt="Page Banner"
          className="object-cover w-full h-full"
        />
        {title && (
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center px-4">
            <h1 className="text-4xl md:text-5xl font-semibold leading-tight max-w-3xl">
              {title}
            </h1>
          </div>
        )}
      </div>

      {/* Breadcrumb Section */}
      <nav className="mt-4 mb-5 px-7">
        <ol className="list-none flex text-sm text-gray-500">
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index} className="flex items-center">
              <a
                href={breadcrumb.link}
                className="hover:underline text-breadCrumbsGrey"
              >
                {breadcrumb.label}
              </a>
              {index < breadcrumbs.length - 1 && (
                <span className="mx-2">{">"}</span>
              )}
            </li>
          ))}
        </ol>
      </nav>

      {/* Description Section */}
      <div className="text-center py-8 px-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4">
          MBA in International Transportation & Logistics Management
        </h2>
        <p className="text-sm md:text-base text-gray-600 max-w-4xl mx-auto">
          {description}
        </p>
      </div>

      {/* Key Highlights Section */}
      <div className="flex flex-col md:flex-row items-stretch justify-between gap-8 mx-14 px-6 md:px-12 py-12">
        {/* Image */}
        <div className="w-full md:w-1/2">
          <div className="relative h-full overflow-hidden rounded-3xl">
            <img
              src="/course_highlight.jpg"
              alt="Shipping"
              className="absolute object-cover w-full h-full"
            />
          </div>
        </div>

        {/* Highlights Content */}
        <div className="w-full md:w-1/2 flex flex-col gap-4">
          <h3 className="text-xl md:text-2xl font-bold">Key Highlights:</h3>
          <ul className="list-none space-y-2 text-gray-900 font-medium">
            <li className="flex items-start gap-2 mb-5">
              IMU-Affiliated Degree with Global Recognition
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> Industry-Focused
              Curriculum Covering International Trade & Logistics
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> Exclusive Shipping
              Campus for Hands-on Training
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> 45-Day Mandatory
              Internship with Leading Logistics Firms
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> Placement Support with
              Top Recruiters
            </li>
          </ul>

          {/* Call-to-Actions */}
          <div>
            <h4 className="text-lg font-bold mt-6">Admissions Open for 2025</h4>
            <div className="flex gap-4 mt-4">
              <button
                className="bg-[#ED1C24] text-white px-6 py-2 rounded-3xl hover:bg-red-600 w-48"
                onClick={() => setIsPopupOpen(true)}
              >
                Apply Now
              </button>
              <button className="border border-gray-400 px-6 py-2 rounded-3xl hover:bg-gray-200 w-48">
                Download Now
              </button>
            </div>
            {/* Contact Information */}
            <p className="mt-4 text-sm text-gray-900 font-medium">
              Need Help? Call us at +91 70250 45000
            </p>
          </div>
        </div>
      </div>
      {/* New Section - What is MBA in Maritime Logistics? */}
      <div className="bg-white py-12 px-4 md:px-8 lg:px-16 xl:px-24">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          What is the MBA in International Transportation & Logistics
          Management?
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          The MBA in International Transportation & Logistics Management at
          EuroTech Maritime Academy is a specialized IMU-affiliated program
          focused on developing future leaders in logistics, port operations,
          freight management, and supply chain analytics. Students gain exposure
          to real-world business challenges through industry-driven coursework,
          internships, and practical case studies.
        </p>

        {/* Why Choose This Program? */}
        <h3 className="text-xl font-bold text-gray-800 mb-4">
          Why Choose This Program?
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <ul className="list-none pl-5 text-gray-700">
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>IMU-Affiliated &
                Globally Recognized MBA Degree
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>Hands-on Training
                in Real-World Logistics & Shipping
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                45-Day Internship with Leading Port & Logistics Companies
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Industry-Oriented Curriculum with Case-Based Learning
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>Strong Industry
                Network & Placement Assistance
              </li>
            </ul>
          </div>
          <div>
            <ul className="list-none pl-5 text-gray-700">
              <li className="flex items-start mb-2">
                <span className="mr-2">📌</span>
                Ideal for students who:
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Want to build a career in global supply chain & logistics
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Seek a management role in maritime, shipping & freight
                forwarding
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Prefer an industry-linked MBA with practical learning
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="bg-[#32586D] text-white flex items-center justify-between py-6 px-12 rounded-3xl my-8 mx-10">
        <h4 className="text-3xl font-semibold">
          Be a Leader in the Global Trade Industry!
        </h4>
        <button
          className="bg-[#ED1C24] hover:bg-red-700 text-white font-bold py-2 px-8 rounded-3xl"
          onClick={() => setIsPopupOpen(true)}
        >
          Apply Now
        </button>
      </div>

      {/* Course Structure and Curriculum Breakdown */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mx-10 mb-5">
        {/* Course Structure */}
        <div className="bg-[#F2F2F2] rounded-3xl p-6">
          <h3 className="text-3xl font-bold text-gray-900 mb-4">
            Course Structure
          </h3>
          <dl className="space-y-2 text-gray-700">
            <div>
              <dt className="font-bold text-lg text-black mb-2">Duration:</dt>
              <dd>2 Years (4 Semesters)</dd>
            </div>
            <div>
              <dt className="font-bold text-lg text-black mt-5 mb-2">
                Eligibility:
              </dt>
              <dd>
                Bachelor's Degree (50% Aggregate, 5% relaxation for SC/ST) +
                Valid IMU-CET Score <br />
                English: 50% (No relaxation)
              </dd>
            </div>
            <div>
              <dt className="font-bold text-lg text-black mb-2">Age Limit:</dt>
              No Age Limit
            </div>
            <div>
              <dt className="font-bold text-lg text-black mb-2 mt-5">
                Fee Structure:
              </dt>
              <dd>
                ₹2.5 Lakhs (Includes Tuition, Study Materials & Certifications)
              </dd>
            </div>
          </dl>
        </div>

        {/* Curriculum Breakdown */}
        <div className="bg-[#F2F2F2] rounded-3xl p-6">
          <h3 className="text-3xl font-bold text-gray-800 mb-4">
            Curriculum Breakdown
          </h3>
          <div className="text-gray-700">
            <p className="font-medium">
              Semester 1 & 2:(Core Business & Logistics Courses)
            </p>
            <ul className="list-none mt-4 space-y-2">
              <li>Maritime Law & Regulations</li>
              <li>International Trade & Port Management</li>
              <li>Logistics & Supply Chain Strategies</li>
              <li>Transport Economics & Freight Analytics</li>
            </ul>
            <p className="font-medium mt-5">
              Semester 3:(Specializations in Logistics & Transportation)
            </p>
            <ul className="list-none mt-4 space-y-2">
              <li>Contemporary Global Supply Chains</li>
              <li>Intermodal Freight Transportation</li>
              <li>Strategic Port & Terminal Operations</li>
            </ul>
            <p className="font-medium mt-5">
              Semester 4:(Capstone Project & Industry Exposure)
            </p>
            <ul className="list-none mt-4 space-y-2">
              <li>
                Capstone Project: Develop real-world solutions for logistics
                firms
              </li>
              <li>
                Internship:45-Day Placement with Top Logistics & Shipping
                Companies
              </li>
              <li>Strategic Port & Terminal Operations</li>
            </ul>
            <p className="font-bold text-lg text-black mb-5 mt-5">
              Certifications Included:
            </p>
            <ul className="list-none">
              <li>Supply Chain & Logistics Analytics Certification</li>
              <li>Maritime Business & Freight Forwarding Training</li>
              <li>NSDC-Government Approved Logistics Certification</li>
            </ul>
          </div>
          <button className="hover:bg-gray-300 border border-gray-400 text-gray-700 font-medium py-2 px-4 rounded-3xl mt-4">
            Download Brochure
          </button>
        </div>
      </div>

      {/* Career Opportunities List */}
      <div className="bg-white mx-10 rounded-lg flex flex-col justify-between mb-20">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Career Opportunities
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-8">
          {/* Career Opportunities List */}
          <div className="bg-white rounded-lg p-7 flex flex-col shadow-[-2px_2px_8px_rgba(0,0,0,0.2)]">
            <div className="grid grid-cols-[1fr_2fr] gap-4">
              <div className="text-gray-900 font-semibold">
                Graduates of MBA in International Transportation & Logistics
                Management can pursue careers as:
              </div>
              <div>
                <div className="text-gray-700 mb-2">
                  Global Supply Chain Manager
                  <hr className="border-gray-300" />
                </div>
                <div className="text-gray-700 mb-2">
                  Port & Shipping Operations Manager
                  <hr className="border-gray-300" />
                </div>
                <div className="text-gray-700 mb-2">
                  Freight Forwarding & Logistics Specialist
                  <hr className="border-gray-300" />
                </div>
                <div className="text-gray-700 mb-2">
                  Maritime Business Consultant
                  <hr className="border-gray-300" />
                </div>
              </div>
            </div>
            <div className="mt-auto flex flex-col">
              <p className="text-gray-900 mt-4 font-bold text-2xl text-left max-w-2xl">
                Launch Your Career in the Booming Logistics Industry!
              </p>
              <button
                className="bg-[#ED1C24] hover:bg-red-700 items-end text-white font-bold py-2 px-8 rounded-3xl mt-4 self-end"
                onClick={() => setIsPopupOpen(true)}
              >
                Apply Now
              </button>
            </div>
          </div>

          {/* Placement Assistance - RIGHT CARD  */}
          <div className="bg-white rounded-lg shadow-md p-6 border border-red-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Placement Assistance:
            </h3>
            <p className="text-gray-700 mb-4">
              Our program connects students with leading shipping & logistics
              firms for internships and placements:
            </p>
            <div className="grid grid-cols-2 gap-4">
              <img
                src="/p1.png"
                alt="Fleet Logo 1"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p2.avif"
                alt="Fleet Logo 2"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p3.png"
                alt="Fleet Logo 3"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p4.jpg"
                alt="Fleet Logo 4"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p5.png"
                alt="Fleet Logo 4"
                className="w-full h-10 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MBACoursePage;
