import React from "react";
import PageBanner from "../Components/PageBanner.jsx";
import { useNavigate } from "react-router-dom";

const ManagementCoursePage = () => {
  const navigate = useNavigate();
  const preSea = [
    {
      title: "BBA in Maritime Logistics",
      link: "/course-booking/bba-maritime-logistics-92",
      imageUrl:
        "https://eurocourse-images.s3.ap-south-1.amazonaws.com/1720681745931.advanced-fire-fighting-aff.jpg",
    },
    {
      title: "MBA in International Transportation & Logistics Management",
      link: "/course-booking/mba-international-transportation-logistics-93",
      imageUrl:
        "https://eurocourse-images.s3.ap-south-1.amazonaws.com/1720681235913.advanced-shipboard-management-asm.jpg",
    },
  ];

  return (
    <div>
      <PageBanner imgUrl="/gim.jpg" title="Management Courses" />
      <div className="container mx-auto my-14 px-3">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {preSea.map((course, index) => (
            <div
              key={index}
              onClick={() => navigate(course.link)}
              className="flex flex-col items-center justify-center cursor-pointer"
            >
              <div
                className="max-w-[250px] md:min-w-[95%] md:max-h-[200px] bg-no-repeat bg-cover md:min-h-[200px] min-h-[180px] min-w-[95%] flex items-end relative rounded-2xl"
                style={{ backgroundImage: `url(${course.imageUrl})` }}
              ></div>
              <h1 className="min-w-[230px] text-center font-bold p-2">
                {course.title}
              </h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManagementCoursePage;
