import React, { useEffect, useRef, useState } from "react";
import NoticeItem from "./NoticeItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { Endpoints } from "../constants/Endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NoticeSlider = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: data?.length < 4 ? data.length : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        // eslint-disable-next-line no-undef
        const res = await axios.get(`${Endpoints.CMS_URL}/dashboard`);
        // console.log(res.data?.data?.latestUpdates, "Data from dashboard api");
        setData(res.data?.data?.latestUpdates);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-4 items-center bg-gradient-to-r from-[#00389e]/50 to-[#00389e]/100 shadow-lg rounded-xl p-6 md:p-8 overflow-hidden relative mx-4 my-6">
        {/* Offer Image */}
        <div className="flex justify-center items-center basis-1/3 mb-4 md:mb-0">
          <img
            src="/assets/special-offer.gif"
            alt="Special Offer"
            className="w-32 h-32 md:w-48 md:h-48 loading='lazy'"
          />
        </div>

        {/* Text Content */}
        <div className="text-white basis-2/3 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold leading-tight mb-2">
            FINAL COUNTDOWN! Offer Ends Monday, March 31st!
          </h2>
          <p className="text-sm md:text-base font-medium text-white mb-4">
            This is it! Secure attractive discounts on our comprehensive
            maritime course packages before the deadline hits. Less than 72
            hours remain! Act fast to enhance your skills and save.
          </p>
          <div>
            <a
              href="/course-booking"
              className="inline-block bg-red-600 text-white px-6 py-3 rounded-full font-bold hover:bg-red-500 transition duration-300 text-sm md:text-base"
            >
              Explore Packages
            </a>
          </div>
        </div>
      </div>

      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-3 mt-10">
        <img
          src="https://eurocourse-images.s3.ap-south-1.amazonaws.com/1740811752426.discount_card.jpg"
          alt="Course 2"
          className="w-full h-full object-cover rounded-3xl cursor-pointer transition-transform transform hover:scale-105 hover:opacity-90"
          onClick={() => navigate("/course-booking")}
        />
        <img
          src="https://eurocourse-images.s3.ap-south-1.amazonaws.com/manage_course_card.png"
          alt="Course 1"
          className="w-full h-full object-cover rounded-3xl cursor-pointer transition-transform transform hover:scale-105 hover:opacity-90"
          onClick={() => navigate("/courses/management-courses")}
        />
        <img
          src="https://eurocourse-images.s3.ap-south-1.amazonaws.com/imost_academy_card.png"
          alt="Course 3"
          className="w-full h-full object-cover rounded-3xl cursor-pointer transition-transform transform hover:scale-105 hover:opacity-90"
          onClick={() => window.open("https://imostacademy.in/", "_blank")}
        />
      </div> */}

      <div className="flex justify-between mx-3 mt-5">
        <h1 className="font-bold text-black  text-[24px] md:text-[36px] mt-10">
          Latest Updates & Notices
        </h1>
        <div className="flex gap-3 items-center">
          <FiArrowLeftCircle
            color="gray"
            size={35}
            strokeWidth="1"
            onClick={() => sliderRef.current.slickPrev()}
          />
          <FiArrowRightCircle
            color="#c6131b"
            size={50}
            strokeWidth="1"
            onClick={() => sliderRef.current.slickNext()}
          />
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {data?.map((item, index) => (
          <NoticeItem key={index} data={item} />
        ))}
      </Slider>
    </div>
  );
};

export default NoticeSlider;
