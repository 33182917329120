import React, { useState } from "react";
import { EN } from "../locale/EN";
import PageBanner from "../Components/PageBanner";
import { routes } from "../constants/routes";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { toast } from "react-toastify";
import { Endpoints } from "../constants/Endpoints";

const GrievanceForm = () => {
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    grievance: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleToggle = () => {
    setIsAnonymous(!isAnonymous);
    if (!isAnonymous) {
      setFormData({
        ...formData,
        name: "",
        contact: "",
        email: "",
      });
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!formData.grievance.trim()) {
      errors.grievance = "Grievance is required";
    }
    if (!isAnonymous && !formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!isAnonymous && !formData.contact.trim()) {
      errors.contact = "Contact is required";
    }
    if (!isAnonymous && !formData.email.trim()) {
      errors.email = "Email is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const payload = {
        grievance: formData.grievance,
        anonymous_status: isAnonymous,
        name: isAnonymous ? "anonymous" : formData.name.trim(),
        contact: isAnonymous ? "anonymous" : formData.contact.trim(),
        email: isAnonymous ? "anonymous" : formData.email.trim(),
      };

      const response = await axios.post(
        `${Endpoints.BASE_URL}/griverance`,
        payload
      );
      setFormData({
        name: "",
        contact: "",
        email: "",
        grievance: "",
      });
      toast.success("Submitted successfully", {
        position: "bottom-center",
        autoClose: 150,
      });
      setIsAnonymous(false);
      setFormErrors({});
    } catch (error) {
      console.error("Error submitting grievance:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Explore Euro Tech Maritime Academy | Grievance Form</title>
        <meta
          name="description"
          content="Discover the essence of Euro Tech Maritime Academy's legacy and commitment in our 'About Us' section. Dive into a world of maritime excellence and innovation. Join us on this journey today"
        />
        <link
          rel="canonical"
          href={"http://localhost:3002" + routes.GRIVERANCE_FORM}
        />
      </Helmet>
      <main>
        <PageBanner
          imgUrl="/assets/about-banner.png"
          title={EN.griverance_form.PAGE_TITLE}
        />
        <div className="container mx-auto mt-10 px-4">
          <div className="flex flex-col md:flex-row">
            {/* Form section */}
            <div className="md:w-1/2 p-4 bg-white shadow-md rounded-lg">
              <h1 className="text-2xl font-bold mb-4">Grievance Form</h1>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Grievance
                  </label>
                  <textarea
                    name="grievance"
                    value={formData.grievance}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border ${
                      formErrors.grievance
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                    rows="4"
                  />
                  {formErrors.grievance && (
                    <p className="mt-1 text-sm text-red-500">
                      {formErrors.grievance}
                    </p>
                  )}
                </div>
                {!isAnonymous && (
                  <>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${
                          formErrors.name ? "border-red-500" : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                      />
                      {formErrors.name && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.name}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact
                      </label>
                      <input
                        type="text"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${
                          formErrors.contact
                            ? "border-red-500"
                            : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                      />
                      {formErrors.contact && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.contact}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${
                          formErrors.email
                            ? "border-red-500"
                            : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                      />
                      {formErrors.email && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.email}
                        </p>
                      )}
                    </div>
                  </>
                )}

                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    checked={isAnonymous}
                    onChange={handleToggle}
                    id="anonymousToggle"
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label
                    htmlFor="anonymousToggle"
                    className="ml-2 text-sm text-gray-700"
                  >
                    Submit Anonymously
                  </label>
                </div>

                <button
                  type="submit"
                  className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </form>
            </div>
            {/* Note section */}
            <div className="md:w-1/2 p-4 mt-4 md:mt-0">
              <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-bold mb-2">Note</h2>
                <p className="mb-2 text-sm text-gray-800">
                  IVRS number:{" "}
                  <a
                    href="tel:+91 9567705273"
                    className="text-blue-600 hover:underline"
                  >
                    9567705273
                  </a>
                </p>
                <p className="mb-2 text-sm text-gray-800">Select: Option 5</p>
                <p className="mb-2 text-sm text-gray-800">
                  Email:{" "}
                  <a
                    href="mailto:grievance@eurotechmaritime.org"
                    className="text-blue-600 hover:underline"
                  >
                    grievance@eurotechmaritime.org
                  </a>
                </p>
                <p className="mb-2 text-sm text-gray-800">
                  Suggestion Box placed at Main Block and K-Block and Hostel
                </p>
                <p className="mb-2 text-sm text-gray-800">
                  Students Grievance Redressal Committee (SGRC) to address the
                  grievances of students through the Website:
                  <a
                    href="http://www.eurotechmaritime.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    www.eurotechmaritime.org
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* committee section */}
          <div className="mt-8">
            <h2 className="text-xl font-bold mb-4">
              Students Grievance Redressal Committee
            </h2>
            <h5 className="m-2">
              A Students Grievance Redressal Committee has been formed in this
              Academy comprises of following members to attend grievance sent
              through this web site
            </h5>
            <div className="bg-gray-100 p-4 m-10 rounded-lg shadow-md overflow-x-auto ">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2">S.No</th>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Designation</th>
                    <th className="px-4 py-2">Email-id</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border px-4 py-2">1</td>
                    <td className="border px-4 py-2">CE Johns Kurian</td>
                    <td className="border px-4 py-2">Chairperson</td>
                    <td
                      className="border px-4 py-2 align-middle text-center"
                      rowSpan="6"
                    >
                      <span className="block mb-2">john.doe@example.com</span>
                      <br />
                      CC: hr@eurotechmaritime.org
                      <br />
                      director@eurotechmaritime.org
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">2</td>
                    <td className="border px-4 py-2">Mr.Satheesh G</td>
                    <td className="border px-4 py-2">Member</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">3</td>
                    <td className="border px-4 py-2">
                      Ms.Priyanka Reghunathaman
                    </td>
                    <td className="border px-4 py-2">Member</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">4</td>
                    <td className="border px-4 py-2">Mr. Midhun Radhakrishnan</td>
                    <td className="border px-4 py-2">Member</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">5</td>
                    <td className="border px-4 py-2">
                      Mr.Girish Chandra Ramachandran
                    </td>
                    <td className="border px-4 py-2">Member</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">6</td>
                    <td className="border px-4 py-2">Mr. Aditya Krishnadas Nair</td>
                    <td className="border px-4 py-2">Member, Student Representative</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">7</td>
                    <td className="border px-4 py-2">Duty HCO</td>
                    <td className="border px-4 py-2">Member</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* Objectives */}
          <div>
            <div className=" mt-8 p-4 rounded-lg shadow-md">
              <h2 className="mb-5 text-xl font-bold">Objectives</h2>
              <ul className="list-disc pl-5 text-sm text-gray-800">
                <li className="mb-2">
                  Upholding the dignity of the Academy by ensuring a
                  trouble-free atmosphere in the Academy through promoting
                  cordial student-student, student-faculty/instructor, and
                  student-staff relationships.
                </li>
                <li className="mb-2">
                  Encouraging students to record their complaints and solve
                  their problems related to academics, resources, and personal
                  grievances freely and frankly without any fear of
                  victimization.
                </li>
                <li className="mb-2">
                  A suggestion/complaint box is placed at various locations, in
                  which the students, who wish to remain anonymous, may drop in
                  their grievances and their suggestions for improving the
                  academics/administration in the Academy.
                </li>
                <li className="mb-2">
                  Ragging, in any form, is strictly prohibited both inside and
                  outside the Academy. Any violation of ragging or disciplinary
                  rules should be urgently brought to the notice of the
                  Principal.
                </li>
                <li className="mb-2">
                  Complaints regarding harassment of women will be handled as
                  per government guidelines by the Students Grievance Complaint
                  Committee.
                </li>
              </ul>
            </div>
          </div>
          {/* Processing of Griverance */}
          <div>
            <div className=" mt-8 mb-10 p-4 rounded-lg shadow-md">
              <h2 className="mb-5 text-xl font-bold">
                Processing of Griverance
              </h2>
              <ul className="list-disc pl-5 text-sm text-gray-800">
                <li className="mb-2">
                  The registered grievance will be submitted to the SGRC within
                  15 working days.
                </li>
                <li className="mb-2">
                  The Chairman of the SGRC will convene a meeting of SGRC on
                  receipt of the grievance. The quorum for the meeting including
                  the Chairperson, but excluding the special invitee, shall be
                  THREE.
                </li>
                <li className="mb-2">
                  The SGRC shall follow principles of natural justice.
                </li>
                <li className="mb-2">
                  The SGRC shall send its report with recommendations, if any,
                  to the Top Management with a copy to the aggrieved student.
                </li>
                <li className="mb-2">
                  Final decision will be taken by the Top Management of Euro
                  Tech Maritime Academy and the decision will be communicated to
                  the aggrieved student by email (if provided).
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default GrievanceForm;
