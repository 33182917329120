import React, { useState } from "react";
import PopupForm from "../Components/coursePopup.jsx";

const BBACoursePage = ({
  title = "India's First IMU-Affiliated Maritime Logistics BBA",
  description = "A specialized 3-year program designed for careers in port operations, supply chain management, and logistics, with an industry-supported 12-month apprenticeship",
  imageSrc = "/course_img.png",
}) => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Courses", link: "/courses-list" },
  ];

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <PopupForm
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        pageMsg="BBA Course Enquiry"
      />

      {/* Image Section */}
      <div className="relative w-full h-[50vh] overflow-hidden">
        <img
          src={imageSrc}
          alt="Page Banner"
          className="object-cover w-full h-full"
        />
        {title && (
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center px-4">
            <h1 className="text-4xl md:text-5xl font-semibold leading-tight max-w-3xl">
              {title}
            </h1>
          </div>
        )}
      </div>

      {/* Breadcrumb Section */}
      <nav className="mt-4 mb-5 px-7">
        <ol className="list-none flex text-sm text-gray-500">
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index} className="flex items-center">
              <a
                href={breadcrumb.link}
                className="hover:underline text-breadCrumbsGrey"
              >
                {breadcrumb.label}
              </a>
              {index < breadcrumbs.length - 1 && (
                <span className="mx-2">{">"}</span>
              )}
            </li>
          ))}
        </ol>
      </nav>

      {/* Description Section */}
      <div className="text-center py-8 px-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4">
          Shape Your Future in Global Trade!
        </h2>
        <p className="text-sm md:text-base text-gray-600 max-w-4xl mx-auto">
          {description}
        </p>
      </div>

      {/* Key Highlights Section */}
      <div className="flex flex-col md:flex-row items-stretch justify-between gap-8 mx-14 px-6 md:px-12 py-12">
        {/* Image */}
        <div className="w-full md:w-1/2">
          <div className="relative h-full overflow-hidden rounded-3xl">
            <img
              src="/course_highlight.jpg"
              alt="Shipping"
              className="absolute object-cover w-full h-full"
            />
          </div>
        </div>

        {/* Highlights Content */}
        <div className="w-full md:w-1/2 flex flex-col gap-4">
          <h3 className="text-xl md:text-2xl font-bold">Key Highlights:</h3>
          <ul className="list-none space-y-2 text-gray-900 font-medium">
            <li className="flex items-start gap-2 mb-5">
              First & Only IMU-Affiliated Maritime Logistics BBA
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> Hands-on Training in a
              Shipping Campus
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> 12-Month NSDC-Government
              Supported Paid Apprenticeship
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> Global Career
              Opportunities in Logistics & Transportation
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✔</span> 25+ Years of Legacy in
              Maritime Education
            </li>
          </ul>

          {/* Call-to-Actions */}
          <div>
            <h4 className="text-lg font-bold mt-6">Admissions Open for 2025</h4>
            <div className="flex gap-4 mt-4">
              <button
                className="bg-[#ED1C24] text-white px-6 py-2 rounded-3xl hover:bg-red-600 w-48"
                onClick={() => setIsPopupOpen(true)}
              >
                Apply Now
              </button>
              <button className="border border-gray-400 px-6 py-2 rounded-3xl hover:bg-gray-200 w-48">
                Download Now
              </button>
            </div>
            {/* Contact Information */}
            <p className="mt-4 text-sm text-gray-900 font-medium">
              Need Help? Call us at +91 70250 45000
            </p>
          </div>
        </div>
      </div>
      {/* New Section - What is BBA in Maritime Logistics? */}
      <div className="bg-white py-12 px-4 md:px-8 lg:px-16 xl:px-24">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          What is BBA in Maritime Logistics?
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          The BBA in Maritime Logistics at EuroTech Maritime Academy is India's
          first and only Indian Maritime University (IMU)-affiliated logistics
          degree program. This 3-year course integrates business fundamentals
          with specialized maritime logistics training, preparing students for
          careers in port operations, freight forwarding, and supply chain
          management.
        </p>

        {/* Why Choose This Program? */}
        <h3 className="text-xl font-bold text-gray-800 mb-4">
          Why Choose This Program?
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <ul className="list-none pl-5 text-gray-700">
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                First-of-its-kind IMU-Affiliated Course
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Industry-Integrated Curriculum with Real-World Exposure
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Apprenticeship-Based Learning Model (Earn While You Learn)
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Exclusive Shipping Campus for Hands-on Training
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Strong Placement Support in Top Shipping & Logistics Firms
              </li>
            </ul>
          </div>
          <div>
            <ul className="list-none pl-5 text-gray-700">
              <li className="flex items-start mb-2">
                <span className="mr-2">📌</span>
                Ideal for students who:
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Have an interest in global trade, logistics, and supply chain
                management
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Want a career in ports, shipping, or logistics consulting
              </li>
              <li className="flex items-center mb-2">
                <span className="mr-2 text-green-500">✔</span>
                Prefer an industry-linked course with an apprenticeship
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="bg-[#32586D] text-white flex items-center justify-between py-6 px-12 rounded-3xl my-8 mx-10">
        <h4 className="text-3xl font-semibold">
          Be a Part of the Future of Maritime Logistics!
        </h4>
        <button
          className="bg-[#ED1C24] hover:bg-red-700 text-white font-bold py-2 px-8 rounded-3xl"
          onClick={() => setIsPopupOpen(true)}
        >
          Apply Now
        </button>
      </div>

      {/* Course Structure and Curriculum Breakdown */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mx-10 mb-5">
        {/* Course Structure */}
        <div className="bg-[#F2F2F2] rounded-3xl p-6">
          <h3 className="text-3xl font-bold text-gray-900 mb-4">
            Course Structure
          </h3>
          <dl className="space-y-2 text-gray-700">
            <div>
              <dt className="font-bold text-lg text-black mb-2">Duration:</dt>
              <dd>3 Years (2 Years Classroom + 1 Year Apprenticeship)</dd>
            </div>
            <div>
              <dt className="font-bold text-lg text-black mt-5 mb-2">
                Eligibility:
              </dt>
              <dd>
                10+2/equivalent (60% Aggregate in any stream, 5% relaxation for
                SC/ST) <br />
                Minimum 50% Marks in English (No Relaxation)
              </dd>
            </div>
            <div>
              <dt className="font-bold text-lg text-black mb-2">Age Limit:</dt>
              <dd>
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th className="text-left p-2">Category</th>
                      <th className="text-left p-2">Male</th>
                      <th className="text-left p-2">Female</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-2">General</td>
                      <td className="p-2">25 Years</td>
                      <td className="p-2">27 Years</td>
                    </tr>
                    <tr>
                      <td className="p-2">OBC (NCL)</td>
                      <td className="p-2">28 Years</td>
                      <td className="p-2">30 Years</td>
                    </tr>
                    <tr>
                      <td className="p-2">SC/ST</td>
                      <td className="p-2">30 Years</td>
                      <td className="p-2">32 Years</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </div>
            <div>
              <dt className="font-bold text-lg text-black mb-2 mt-5">
                Fee Structure:
              </dt>
              <dd>
                ₹1 Lakh per Year (₹37,500 per semester) <br />+ ₹25,000 annual
                program fee)
                <br />
                Hostel Fee (Optional for Female Students): ₹60,000/year
              </dd>
            </div>
          </dl>
        </div>

        {/* Curriculum Breakdown */}
        <div className="bg-[#F2F2F2] rounded-3xl p-6">
          <h3 className="text-3xl font-bold text-gray-800 mb-4">
            Curriculum Breakdown
          </h3>
          <div className="text-gray-700">
            <p className="font-medium">Years 1 & 2: (Classroom Learning)</p>
            <ul className="list-none mt-4">
              <li>Financial Accounting</li>
              <li>Maritime Law</li>
              <li>Supply Chain Management</li>
              <li>EXIM Policies</li>
              <li>International Trade & Logistics</li>
            </ul>
            <p className="font-medium mt-2">
              Year 3: (12-Month Apprenticeship - NSDC Supported)
            </p>
            <p className="mb-4 mt-4">
              Real-time experience in port operations, freight forwarding, and
              logistics firms
            </p>
            <p className="font-bold text-lg text-black mb-5">
              Certifications Included:
            </p>
            <ul className="list-none">
              <li>NSDC-Government Recognized Training</li>
              <li>Maritime Business Operations Certification</li>
              <li>Global Logistics & Trade Management Certificate</li>
            </ul>
          </div>
          <button className="hover:bg-gray-300 border border-gray-400 text-gray-700 font-medium py-2 px-4 rounded-3xl mt-4">
            Download Brochure
          </button>
        </div>
      </div>

      {/* Career Opportunities List */}
      <div className="bg-white mx-10 rounded-lg flex flex-col justify-between mb-20">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Career Opportunities
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-8">
          {/* Career Opportunities List */}
          <div className="bg-white rounded-lg p-7 flex flex-col shadow-[-2px_2px_8px_rgba(0,0,0,0.2)]">
            <div className="grid grid-cols-[1fr_2fr] gap-4">
              <div className="text-gray-900 font-semibold">
                Graduates of BBA in Maritime Logistics can pursue careers in:
              </div>
              <div>
                <div className="text-gray-700 mb-2">
                  Port Operations & Management
                  <hr className="border-gray-300" />
                </div>
                <div className="text-gray-700 mb-2">
                  Supply Chain & Logistics
                  <hr className="border-gray-300" />
                </div>
                <div className="text-gray-700 mb-2">
                  Freight Forwarding & Customs Handling
                  <hr className="border-gray-300" />
                </div>
                <div className="text-gray-700 mb-2">
                  Maritime Business Consulting
                  <hr className="border-gray-300" />
                </div>
                <div className="text-gray-700 mb-2">
                  Warehouse & Inventory Management
                  <hr className="border-gray-300" />
                </div>
              </div>
            </div>
            <div className="mt-auto flex flex-col">
              <p className="text-gray-900 mt-4 font-bold text-2xl text-left max-w-2xl">
                Launch Your Career in the Booming Logistics Industry!
              </p>
              <button
                className="bg-[#ED1C24] hover:bg-red-700 items-end text-white font-bold py-2 px-8 rounded-3xl mt-4 self-end"
                onClick={() => setIsPopupOpen(true)}
              >
                Apply Now
              </button>
            </div>
          </div>

          {/* Placement Assistance - RIGHT CARD  */}
          <div className="bg-white rounded-lg shadow-md p-6 border border-red-500">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Placement Assistance:
            </h3>
            <p className="text-gray-700 mb-4">
              Our program connects students with leading shipping & logistics
              firms for internships and placements:
            </p>
            <div className="grid grid-cols-2 gap-4">
              <img
                src="/p1.png"
                alt="Fleet Logo 1"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p2.avif"
                alt="Fleet Logo 2"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p3.png"
                alt="Fleet Logo 3"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p4.jpg"
                alt="Fleet Logo 4"
                className="w-full h-10 object-contain"
              />
              <img
                src="/p5.png"
                alt="Fleet Logo 4"
                className="w-full h-10 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BBACoursePage;
