import React, { useState } from "react";
import { routes } from "../constants/routes";
import { Link, useNavigate } from "react-router-dom";
import { Endpoints } from "../constants/Endpoints";
import axios from "axios";
import { setUserProfileData, setTimestamp } from "../redux/ActionCreator";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const LoginPage = () => {
  const [verificationNum, setVerificationNum] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const res = await axios.post(
      `${Endpoints.API_URL}candidate/login`,
      {
        // email,
        verificationNum,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.statusCode !== 201) {
      setIsLoading(false);
      toast.error(res.data.message);
    } else {
      // setIsSent(true);
      let t = new Date();

      setIsLoading(false);
      toast.success("Candidate Login Success");
      dispatch(setUserProfileData(res?.data?.data));
      dispatch(setTimestamp(t.getTime()));
      navigate(routes.HOME);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
        <link rel="canonical" href="https://eurotechmaritime.org/login" />
      </Helmet>
      <div
        className="w-screen h-screen flex justify-center items-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: "url(/assets/Home-hero-banner.png)",
        }}
      >
        <div className="bg-white h-[90vh] w-[90vw] max-h-[600px] max-w-[1400px] rounded-2xl border">
          <div className="h-full w-full grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
            <div className="hidden md:flex justify-center items-center p-4">
              <img
                src="/assets/Login-image.svg"
                alt="Login visual"
                className="max-w-[80%] h-auto"
              />
            </div>
            <div className="px-4 md:px-14 py-5 lg:py-14">
              <div className="flex justify-end mb-4">
                <Link
                  to={routes.HOME}
                  className="text-lg font-semibold text-gray-600 hover:text-gray-800"
                >
                  ×
                </Link>
              </div>
              <form onSubmit={handleLogin}>
                <h1 className="text-3xl md:text-[35px] font-bold text-gray-800">
                  Already Registered?
                </h1>
                <p className="text-gray-600 text-sm md:text-[14px] mt-3 mb-6">
                  Login into your Account
                </p>

                <div className="flex flex-col gap-5">
                  <input
                    type="text"
                    value={verificationNum}
                    required
                    className="border border-gray-300 rounded-xl h-12 md:h-[50px] w-full px-4 placeholder-gray-400 focus:ring-2 focus:ring-blue-200 focus:border-blue-500"
                    placeholder="Enter Email Address or Phone No or Passport No or INDoS No"
                    onChange={(e) => setVerificationNum(e.target.value)}
                  />

                  {isLoading && (
                    <p className="text-sm md:text-[14px] text-blue-600 font-semibold">
                      Please Wait...
                    </p>
                  )}
                </div>

                <button
                  className="bg-[#1550A2] hover:bg-blue-700 w-full text-white py-3 md:py-4 rounded-xl text-lg font-semibold mt-6 lg:mt-8 mb-4 lg:mb-8 transition-colors"
                  type="submit"
                >
                  Continue
                </button>
              </form>

              <p className="text-gray-800 md:text-[18px] text-lg">
                New Here?{" "}
                <Link to={routes.REGISTER}>
                  <button className="bg-[#1550A2] hover:bg-blue-800 text-white rounded-xl font-semibold py-2 px-4 transition-colors">
                    Create an Account
                  </button>
                </Link>
              </p>

              {/* Added text for login issues */}
              <p className="text-gray-600 text-sm md:text-lg mt-4">
                If you face any issues while logging in, please contact us at{" "}
                <a
                  href="tel:+917025045000"
                  className="font-semibold text-[#1550A2] hover:underline"
                >
                  +91 70250 45000
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
