import React from "react";

const PriceDetailsBox = ({ totalActualFees, totalDiscountAmount }) => {
  const discountedTotal = totalActualFees - totalDiscountAmount;

  return (
    <div className="mt-6 w-60 pt-4">
      <div className="flex border-b border-[#878787] py-2 justify-between items-center">
        <span className="text-lg max-sm:text-base font-medium">SUB TOTAL</span>
        <span className="text-lg max-sm:text-base font-semibold">
          ₹ {totalActualFees}
        </span>
      </div>

      {totalDiscountAmount > 0 && (
        <div className="flex border-b border-[#878787] py-2 justify-between items-center mt-2">
          <span className="text-lg max-sm:text-base font-medium">DISCOUNT</span>
          <span className="text-lg max-sm:text-base font-semibold">
            - ₹ {totalDiscountAmount}
          </span>
        </div>
      )}

      <div className="flex border-b border-[#878787] py-2 justify-between items-center mt-2">
        <span className="text-lg max-sm:text-base font-medium">TOTAL</span>
        <span className="text-lg max-sm:text-base font-semibold">
          ₹ {discountedTotal}
        </span>
      </div>
      <div className="border-b border-[#878787] mt-1"></div>
    </div>
  );
};

export default PriceDetailsBox;
