import axios from "axios";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Endpoints } from "../constants/Endpoints.js";

const PopupForm = ({ isOpen, onClose, pageMsg }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    state: "",
    email: "",
    message: pageMsg,
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${Endpoints.API_URL}/contact/add`,
        formData
      );

      if (response.status === 200 && response.data.statusCode === 200) {
        setResponseMessage("Form submitted successfully!");
        setIsSuccess(true);
        setFormData({ name: "", phone: "", state: "", email: "", message: pageMsg });
        setTimeout(() => {
          setResponseMessage("");
          setIsSuccess(null);
          onClose();
        }, 2000);
      } else {
        setResponseMessage("Submission failed. Please try again.");
        setIsSuccess(false);
      }
    } catch (error) {
      setResponseMessage("An error occurred. Please try again later.");
      setIsSuccess(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          <AiOutlineClose size={20} />
        </button>
        <h2 className="text-xl font-bold mb-4">Fill in Your Details</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Mobile"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={formData.state}
            onChange={handleChange}
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded mb-2"
            required
          />
          <input type="hidden" name="message" value={formData.message} />
          {responseMessage && (
            <p className={`text-center text-sm mt-2 ${isSuccess ? "text-green-500" : "text-red-500"}`}>
              {responseMessage}
            </p>
          )}
          <button
            type="submit"
            className="bg-blue-500 text-white w-full p-2 rounded hover:bg-blue-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;