import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import CourseDetailItem from "../Components/CourseDetailItem";
import PageBanner from "../Components/PageBanner";
import PriceDetailsBox from "../Components/PriceDetailsBox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BlueButton from "../Components/BlueButton";
import { Endpoints } from "../constants/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../constants/routes";
import { decreaseCartCount, updateCartCount } from "../redux/ActionCreator";
import { Helmet } from "react-helmet-async";
import PackageCourseItem from "../Components/PackageCourseItems";
import { MdOutlineCancel } from "react-icons/md";
import { toast } from "react-toastify";

const CourseDetails = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [update, setUpdate] = useState(false);
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const dispatch = useDispatch();
  const [batchList, setBatchList] = useState([]);
  const [packageData, setPackageData] = useState();

  const [totalActualFees, setTotalActualFees] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const fetchPackages = async () => {
    try {
      const res = await axios.get(`${Endpoints.BASE_URL}/package/list`);
      setPackageData(res.data.resultMessage.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  // Fetching batch list
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${Endpoints.API_URL}courses/candidate-courses/list`,
          {
            headers: {
              Authorization: `Bearer ${profileDetails.token}`,
            },
          }
        );
        setBatchList(res.data.data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [profileDetails.token]);

  // Fetching cart data

  const getCart = async () => {
    try {
      const res = await axios.get(`${Endpoints.BASE_URL}/cart/get-cart`, {
        headers: {
          Authorization: `Bearer ${profileDetails.token}`,
        },
      });
      setTotalAmount(res.data.data.totalAmount);
      setCartData(res.data.data.cart);
      dispatch(updateCartCount(res.data.data.cart.length));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.BASE_URL}/cart/get-cart`, {
          headers: {
            Authorization: `Bearer ${profileDetails.token}`,
          },
        });
        setTotalAmount(res.data.data.totalAmount);
        setCartData(res.data.data.cart);
        dispatch(updateCartCount(res.data.data.cart.length));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [update, profileDetails.token, dispatch]);

  // Cart items processing
  const coursesWithoutPackage = [];
  const coursesWithPackages = [];

  cartData.forEach((item) => {
    if (item.isPackage === false) {
      coursesWithoutPackage.push(item);
    } else {
      coursesWithPackages.push(item);
    }
  });

  // Remove item handler
  const removeItem = async (id) => {
    console.log("id", id);
    try {
      const res = await axios.delete(`${Endpoints.BASE_URL}/cart/remove-item`, {
        headers: {
          Authorization: `Bearer ${profileDetails.token}`,
        },
        data: { cart_id: id },
      });
      dispatch(decreaseCartCount());
      setUpdate(!update);
    } catch (error) {
      console.error("Error removing course from cart:", error);
    }
  };

  const ClearCart = async () => {
    try {
      const res = await axios.delete(`${Endpoints.BASE_URL}/cart/clear-cart`, {
        headers: {
          Authorization: `Bearer ${profileDetails.token}`,
        },
      });
      await getCart();
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrder = async () => {
    await axios.get(`${Endpoints.API_URL}hylo/order-status`, {
      headers: {
        Authorization: `Bearer ${profileDetails.token}`,
      },
    });
  };

  useEffect(() => {
    updateOrder();
  }, []);

  const calculateFeesAndDiscounts = async (courseIds, packages) => {
    let totalActualFees = 0;
    let totalDiscountAmount = 0;

    for (const courseId of courseIds) {
      try {
        const response = await axios.get(
          `${Endpoints.API_URL}/courses/fetch/${courseId}`
        );
        const courseData = response.data.data;

        const actualFees = parseFloat(courseData.fees);
        const discountPercentage = parseFloat(courseData.discount);

        const discountAmount = (actualFees * discountPercentage) / 100;

        totalActualFees += actualFees;
        totalDiscountAmount += discountAmount;
      } catch (error) {
        console.error(`Error fetching course ${courseId}:`, error);
      }
    }

    // calculate price and disconut for package here
    if (packages?.length > 0) {
      packages.forEach((pkg) => {
        const packageItem = packageData.find((p) => p.id === pkg.item_id);
        if (packageItem) {
          const price = parseFloat(packageItem.price);
          const discount = parseFloat(packageItem.discount);

          totalActualFees += price;
          totalDiscountAmount += (price * discount) / 100;
        }
      });
    }

    return { totalActualFees, totalDiscountAmount };
  };

  useEffect(() => {
    const courseIds = coursesWithoutPackage.map((item) => item.item_id);
    calculateFeesAndDiscounts(courseIds, coursesWithPackages).then((result) => {
      setTotalActualFees(result.totalActualFees);
      setTotalDiscountAmount(result.totalDiscountAmount);
    });
  }, [coursesWithoutPackage, coursesWithPackages]);

  return (
    <div>
      <Helmet>
        <title>Booking Summary | Maritime Career</title>
        <meta
          name="description"
          content="Discover boundless opportunities at Euro Tech Maritime Academy. Contact us now to chart your course to a successful maritime career. Join the leading academy today"
        />
        <link rel="canonical" href="https://eurotechmaritime.org/contact-us" />
      </Helmet>

      <PageBanner imgUrl="/assets/About-bg-image.png" title="ORDER SUMMARY" />

      <div className="flex justify-center items-center">
        <section className="container flex flex-col mx-auto px-2 md:px-0">
          <div className=" flex justify-between items-center px-4">
            <h1 className="flex items-center text-gray-500 text-[20px] md:text-[36px] font-bold gap-4 my-5">
              <FiArrowLeft onClick={() => navigate(routes.COURSE_BOOKING)} />
              Order Summary
            </h1>
            <button
              onClick={ClearCart}
              className=" p-2 bg-red-600 text-white rounded-md"
            >
              Clear Cart
            </button>
          </div>

          {coursesWithPackages.length > 0 ||
          coursesWithoutPackage.length > 0 ? (
            <div className="mb-14">
              <div className="flex flex-col gap-5">
                {coursesWithPackages &&
                  coursesWithPackages.map((item, i) => {
                    const packageItem =
                      packageData &&
                      packageData.find(
                        (pkg) => String(pkg.id) === String(item.item_id)
                      );

                    const courseBatches = item.batch_id;

                    const allBatchesDefined = packageItem.courses.every(
                      (data) => {
                        const batchIdForCourse = courseBatches[data.id];
                        if (!batchIdForCourse) {
                          toast.error(
                            `Please add a batch for all courses in the package: ${packageItem?.packageName}`
                          );
                          removeItem(item.id);
                          return false;
                        }
                        return true;
                      }
                    );

                    if (!allBatchesDefined) {
                      return null;
                    }

                    return (
                      <div key={i} className=" p-4 ">
                        <div className="shadow-md p-4  border-gray-300  transform hover:shadow-lg hover:-translate-y-1 transition-all duration-300 ease-in-out box-border border rounded-lg">
                          <div className="flex gap-2 items-center ">
                            <button
                              className="hover:bg-red-600 hover:text-white rounded-full shadow-sm text-gray-800 !text-3xl m-2 transition duration-300 ease-in-out"
                              onClick={() => removeItem(item.id)}
                            >
                              <MdOutlineCancel />
                            </button>
                            {/* Conditional rendering for package name */}
                            <div className=" flex justify-between items-center w-full ">
                              <h1 className=" font-bold text-lg text-[#205277]">
                                {packageItem && packageItem?.packageName}
                              </h1>

                              <div className="flex items-center">
                                {packageItem && packageItem?.discount > 0 ? (
                                  <div>
                                    <div className="flex items-baseline">
                                      <p className="text-red-600 text-lg line-through mr-2">
                                        ₹{packageItem?.price}
                                      </p>
                                      <p className="text-lg font-semibold">
                                        ₹
                                        {packageItem?.price -
                                          (packageItem?.price *
                                            packageItem?.discount) /
                                            100}
                                      </p>
                                    </div>
                                    <p className="text-sm text-gray-600">
                                      Limited Time Offer
                                    </p>
                                  </div>
                                ) : (
                                  <p className="!text-nowrap font-semibold text-lg">
                                    ₹{packageItem && packageItem?.price}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          {packageItem.courses.map((data) => {
                            const batchIdForCourse = courseBatches[data.id];

                            return (
                              <div key={data.id} className="my-2">
                                <PackageCourseItem
                                  data={data}
                                  batches={batchIdForCourse}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="flex flex-col gap-5  p-4">
                {coursesWithoutPackage.map((item) => (
                  <CourseDetailItem
                    key={item.id}
                    data={item}
                    update={update}
                    setUpdate={setUpdate}
                    batches={batchList}
                  />
                ))}
              </div>

              <div className="flex justify-end px-4">
                <PriceDetailsBox
                  totalActualFees={totalActualFees}
                  totalDiscountAmount={totalDiscountAmount}
                />
              </div>

              <ul className="text-[#D8272F] my-5 px-4 list-disc">
                <li>
                  Please ensure that your recent photograph and signature are
                  uploaded and visible in the Seafarer’s profile. If not, kindly
                  re-upload them at the earliest.
                </li>
                <li>
                  Your order summary will expire in 48 hours. Be sure to
                  complete your purchase before the time runs out to secure your
                  selected items.
                </li>
              </ul>

              <div className="flex flex-col gap-3 md:flex-row justify-end">
                <BlueButton onClick={() => navigate(-1)}>
                  Take another course
                </BlueButton>
                <BlueButton
                  onClick={() =>
                    navigate(routes.COURSE_BOOKING + routes.COURSE_TERMS)
                  }
                >
                  Proceed to Payment
                </BlueButton>
              </div>
            </div>
          ) : (
            <>
              <h1 className="text-black text-[40px] font-bold my-14 text-center">
                No Courses Selected
              </h1>
              <ul className="text-[#D8272F] my-5 px-4 list-disc">
                <li>
                  Courses in your order summary will be automatically removed if
                  the selected batch has already commenced or if all seats are
                  filled.
                </li>
                <li>
                  Your order summary will expire in 48 hours. Be sure to
                  complete your purchase before the time runs out to secure your
                  selected items.
                </li>
              </ul>
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default CourseDetails;
